export enum ObligationType {
  BTP_CARD = 'BTP_CARD',
  EMPLOYMENT_PROOF = 'EMPLOYMENT_PROOF',
  NATIONALITY_PROOF = 'NATIONALITY_PROOF',
  PICTURE = 'PICTURE',
}

export enum ObligationStatus {
  INVALID = 'INVALID',
  VALID = 'VALID',
}

type Obligation = {
  id?: string;
  resourceId: string;
  organisationId: string;
  resource: {
    id: string;
    firstName: string;
    lastName: string;
  };
  organisation: {
    id: string;
    name: string;
    nationalId: string;
  };
  status: ObligationStatus;
  type: ObligationType;
  validDocumentVersionId?: string;
};

export default Obligation;
