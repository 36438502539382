/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
type Primitive = string | number | boolean | null | undefined;
type NestedObject = { [key: string]: Primitive | NestedObject | NestedObject[] } | Record<string, unknown>;

interface BufferConstructor {
  isBuffer(obj: unknown): boolean;
}

function isBuffer(obj: unknown): boolean {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }
  const constructor = Object.getPrototypeOf(obj)?.constructor;
  if (typeof constructor?.isBuffer !== 'function') {
    return false;
  }
  return (constructor as BufferConstructor).isBuffer(obj);
}
function keyIdentity(key: string): string {
  return key;
}

interface FlattenOptions {
  delimiter?: string;
  maxDepth?: number;
  transformKey?: (key: string) => string;
  safe?: boolean;
}

export function flatten(target: NestedObject, opts: FlattenOptions = {}): Record<string, Primitive> {
  const delimiter = opts.delimiter || '.';
  const maxDepth = opts.maxDepth;
  const transformKey = opts.transformKey || keyIdentity;
  const output: Record<string, Primitive> = {};
  const seen = new WeakSet<NestedObject>();

  function step(object: NestedObject, prev: string | null, currentDepth: number): void {
    if (seen.has(object)) {
      output[prev || ''] = '[Circular]' as Primitive;
      return;
    }

    seen.add(object);
    currentDepth = currentDepth || 1;
    Object.entries(object).forEach(([key, value]) => {
      const isarray = opts.safe && Array.isArray(value);
      const type = Object.prototype.toString.call(value);
      const isbuffer = isBuffer(value);
      const isobject = type === '[object Object]' || type === '[object Array]';

      const newKey = prev ? prev + delimiter + transformKey(key) : transformKey(key);

      if (
        !isarray &&
        !isbuffer &&
        isobject &&
        typeof value === 'object' &&
        value !== null &&
        Object.keys(value).length &&
        (!opts.maxDepth || currentDepth < maxDepth)
      ) {
        return step(value as NestedObject, newKey, currentDepth + 1);
      }

      output[newKey] = value as Primitive;
    });
  }

  step(target, null, 1);

  return output;
}
