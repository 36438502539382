import { LOCALE_ID, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CoreModule } from './core/core.module';
import { IconRegistryService } from './core/services/icon-registry.service';
import { BASE_PATH } from './shared/api';
import { environment } from 'src/environments/environment';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { OAuthModule, OAuthModuleConfig } from 'angular-oauth2-oidc';
import { authModuleConfig } from 'app/auth-module-config.constant';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { DefaultOAuthInterceptor } from 'app/default-oauth.interceptor';
import { API_V2_PATH, BO_ADDR } from 'app/shared/api-overrides/apiV2/variables';

export function translatePartialLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'i18n/', `.json?buildTimestamp=${process.env.BUILD_TIMESTAMP}`);
}

@NgModule({
  imports: [
    OAuthModule.forRoot(),
    CoreModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translatePartialLoader,
        deps: [HttpClient],
      },
    }),
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
  ],
  providers: [
    {
      provide: BASE_PATH,
      useValue: environment.apiUrl,
    },
    {
      provide: API_V2_PATH,
      useValue: environment.apiV2Url,
    },
    {
      provide: BO_ADDR,
      useValue: environment.boAddr,
    },
    {
      provide: LOCALE_ID,
      useValue: environment.defaultLanguage,
    },
    {
      provide: OAuthModuleConfig,
      useValue: authModuleConfig,
    },
    { provide: HTTP_INTERCEPTORS, useClass: DefaultOAuthInterceptor, multi: true },
  ],
  exports: [CoreModule],
})
export class AppConfigModule {
  constructor(iconRegistryService: IconRegistryService) {
    iconRegistryService.registerIcons();

    if (environment.defaultLanguage === 'en') {
      registerLocaleData(localeEn, 'en');
    } else if (environment.defaultLanguage === 'fr') {
      registerLocaleData(localeFr, 'fr');
    }
  }
}
