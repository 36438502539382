import { flatten } from 'app/utils/flatten';

const toFormData = (data: Record<string, unknown>, preventFlatten = false): FormData => {
  const formData = new FormData();
  Object.entries(preventFlatten ? data : flatten(data)).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (preventFlatten) {
        value.forEach((val) => {
          formData.append(`${key}[]`, val as string | Blob);
        });
      } else {
        formData.append(`${key}[]`, '');
      }
    } else {
      formData.append(key, value as string | Blob);
    }
  });

  return formData;
};

export default toFormData;
