import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NotificationService } from 'app/core/services/notification.service';
import {
  ErrorVm,
  ResourceCreateRequestVm,
  ResourcesService,
  ResourceUpdateRequestVm,
  ResourceVm,
} from 'app/shared/api';
import { map, Observable, ObservableInput, of, switchMap } from 'rxjs';
import { DocumentFile } from 'app/shared/models/document-file';
import { DocumentFileMapper } from 'app/shared/mappers/document-file.mapper';
import { ResourceForm } from './resource-form.model';
import { DEFAULT_HUMAN_RESOURCE_FORM } from './resource-form.constants';
import { ResourceFormMapper } from './resource-form-mapper';
import { AbstractFormComponent } from 'app/shared/abstract-classes/abstract-form-component';
import { NgControl, NgForm } from '@angular/forms';
import { LoaderService } from 'app/core/services/loader.service';
import { maxAge, minAge } from '../../models/resource.constants';
import { ResourceV2Service } from 'app/shared/api-overrides/apiV2';
import { ResourceUnitView } from 'app/shared/api-overrides/apiV2/entities/Resource';

@Component({
  selector: 'sywa-resource-form',
  templateUrl: './resource-form.component.html',
  styleUrls: ['./resource-form.component.scss'],
})
export class ResourceFormComponent extends AbstractFormComponent implements OnChanges {
  @Input() organisationId: string;
  @Input() resourceToEdit: ResourceUnitView;

  @Output() submitSucceeded = new EventEmitter<string>();

  employmentTypeList = Object.values(ResourceCreateRequestVm.TypeOfEmploymentEnum);
  EmploymentTypes = ResourceCreateRequestVm.TypeOfEmploymentEnum;

  resourceForm: ResourceForm = {} as ResourceForm;
  documentFiles: DocumentFile[];

  submitted = false;
  isEditMode = false;

  minAge = minAge;
  maxAge = maxAge;

  @ViewChild('resourceNgForm') private resourceNgForm: NgForm;
  @ViewChild('dropzonefiles') private dropzonefiles: NgControl;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    public notificationService: NotificationService,
    private documentFileMapper: DocumentFileMapper,
    private resourcesService: ResourcesService,
    private resourcesV2Service: ResourceV2Service,
    private resourceFormMapper: ResourceFormMapper,
    private loaderService: LoaderService
  ) {
    super(documentFileMapper);
    this.resourceForm = DEFAULT_HUMAN_RESOURCE_FORM;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.resourceToEdit) {
      this.isEditMode = !!this.resourceToEdit;
      if (this.isEditMode) {
        this.retrievePicture$ = this.resourcesV2Service.getResourcePicture(this.resourceToEdit.picture);
        this.retrievePicture();

        this.resourceForm = this.resourceFormMapper.fromResourceVm(this.resourceToEdit);
      }
    }
  }

  submitResource(): void {
    this.submitted = true;
    this.resourceForm.employerId = this.organisationId;

    if (this.resourceForm.typeOfEmployment !== ResourceCreateRequestVm.TypeOfEmploymentEnum.TempWorker) {
      this.resourceForm.nationalIdTwc = null;
    }

    let request$: Observable<string>;

    if (this.isEditMode) {
      const picture = this.getPicture();

      request$ = of(this.resourceFormMapper.toResourceUpdateRequestVm(this.resourceForm)).pipe(
        switchMap((resourceUpdate: ResourceUpdateRequestVm) =>
          this.resourcesV2Service.updateResource(this.resourceToEdit.id, {
            ...resourceUpdate,
            picture,
          })
        ),
        map(() => this.resourceToEdit.id)
      );
    } else {
      request$ = of(this.resourceFormMapper.toResourceCreateRequestVm(this.resourceForm)).pipe(
        switchMap(
          (resourceCreate: ResourceCreateRequestVm) =>
            this.resourcesV2Service.createResourceV2({
              ...resourceCreate,
              ...(this.documentFiles?.length > 0 ? { picture: this.documentFiles[0].content } : {}),
            }) as ObservableInput<ResourceVm>
        ),
        map((resource: ResourceVm) => resource.id)
      );
    }

    this.loaderService.open();
    request$.subscribe({
      next: (savedResourceId: string) => {
        this.loaderService.close();
        this.notificationService.notifySuccess();
        this.submitSucceeded.emit(savedResourceId);
        this.resourceNgForm.form.reset();
      },
      error: (error: HttpErrorResponse) => {
        this.loaderService.close();
        this.submitted = false;
        const errorVm: ErrorVm = error as unknown as ErrorVm;
        this.notificationService.notifyError(errorVm.message);
      },
    });
  }

  isDropzoneDirty(): boolean {
    return this.dropzonefiles.dirty;
  }

  private getPicture = (): null | string | Blob => {
    if (!this.documentFiles?.length) {
      // no picture
      return null;
    }

    const file = this.documentFiles[0];

    if (!file.name) {
      // picture didn't change
      return this.resourceToEdit.picture;
    }

    // new picture to upload
    return file.content;
  };
}
