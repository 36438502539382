import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { AbstractControlValueAccessor } from 'app/shared/abstract-classes/abstract-control-value-accessor';
import { Observable, Subject, takeUntil } from 'rxjs';
import { DateUtcString } from '../../../models/date-input.model';

import moment from 'moment';

@Component({
  selector: 'sywa-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DateInputComponent), multi: true }],
})
export class DateInputComponent extends AbstractControlValueAccessor<DateUtcString> implements OnInit, OnDestroy {
  @Input() labelKey: string;
  @Input() matFormFiedAppearance: MatFormFieldAppearance = 'standard';
  @Input() disabled = false;
  @Input() required = false;
  @Input() id: string;
  @Input() min: string;
  @Input() max: string;

  dateControl: FormControl;

  private destroyed$: Subject<void> = new Subject();

  constructor() {
    super();
    this.dateControl = new FormControl();
  }

  ngOnInit(): void {
    this.getDateControlValueChanges()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (!this.dateControl.value) {
          this.value = undefined;
        } else {
          const controlValueIsoString = moment(this.dateControl.value as Date)
            .utc(true)
            .startOf('day')
            .toISOString();

          if (this.value !== controlValueIsoString) {
            this.value = controlValueIsoString;
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  writeValue(dateUtcString: DateUtcString): void {
    if (dateUtcString !== null) {
      super.writeValue(dateUtcString);
      this.dateControl.setValue(dateUtcString ? moment(dateUtcString) : undefined);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getDateControlValueChanges(): Observable<any> {
    return this.dateControl.valueChanges;
  }
}
